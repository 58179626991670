/**
 * CITY PAGE MIDDLEWARE *************************
 * *************************************************
 *
 * This middleware is executed for URLs in following the path format:
 * <property-type>/<continent>/<country>/<city>/
 *
 * It must know how to handle the following paths:
 *
 * #### NATIVE STATICWEB - WITH VALID PATHS:
 * /hostels/europe/spain/barcelona/
 * /pt/albergues/europa/espanha/barcelona/
 * /it/ostelli/europa/spagna/barcellona/
 *
 * #### NATIVE STATICWEB - INVALID PROPERTY TYPE:
 * /banana/europe/spain/barcelona         ---> /hostels/europe/spain/barcelona/
 * /pt/banana/europa/espanha/barcelona/   ---> /pt/albergues/europa/espanha/barcelona/
 * /it/banana/europa/spagna/barcellona/   ---> /it/ostelli/europa/spagna/barcellona/
 *
 * #### NATIVE STATICWEB - WITH LANGUAGE MISMATCH:
 * /hostels/europe/england/londres/         ---> /hostels/europe/england/london/
 * /pt/albergues/europa/inglaterra/london/  ---> /pt/albergues/europa/inglaterra/londres/
 * /it/ostelli/europa/inghilterra/london/   ---> /it/ostelli/europa/inghilterra/londra/
 *
 * #### OLD RWD PATHS (AKA REDIRECTION API)
 * /hostels/area/palawan/philippines/ (Area)                      ---> /hostels/asia/philippines/a/palawan/
 * /hostels/region/bali/indonesia/ (Region)                       ---> /hostels/asia/indonesia/r/bali/
 * /hosteldetails.php/Ostello-WeBologna/Bologna/99714/ (Property) ---> /hostels/p/99714/combo-bologna/
 * /pt/albergues/area/palawan/filipinas/ (Area)                   ---> /pt/albergues/asia/filipinas/a/palawan/
 * /pt/albergues/regiao/bali/indonesia/ (Region)                  ---> /pt/albergues/asia/Indonesia/r/bali/
 *
 * #### INVALID COUNTRY (AKA 404)
 * /hostels/europe/portugal/banana/
 * /pt/albergues/europa/portugal/banana/
 * /banana/area/palawan/philippines/
 * /banana/region/bali/indonesia/
 * /pt/banana/area/palawan/filipinas/
 * /pt/banana/regiao/bali/indonesia/
 */

import { useUrls } from '@/composables/useUrls';
import { useApiCities } from '~/composables/useApiCities';
import { useRoutes } from '~/composables/useRoutes.js';

export default defineNuxtRouteMiddleware(async (to) => {
  if (import.meta.server) {
    const nuxtApp = useNuxtApp();

    // If the error is already being handled, skip the validation.
    if (nuxtApp.payload.error) {
      return;
    }

    // ## COMPOSABLES
    const { getCityByCityNameAndCountryName } = useApiCities();
    const { useRouteManager } = useRoutes();
    const { useUrl } = useUrls();

    const urlParams = to?.params;
    const urlQuery = to?.query;
    const urlPath = to?.path.toLowerCase();

    // VALIDATE PROPERTY TYPE
    // If invalid property type, then default to 'hostels' property type (according to the user language);
    const propertyTypeObj = useRouteManager.validatePropertyTypeParam(urlParams.propertyType);

    // VALIDATE CITY WITH CALL TO SPAPI
    const locationObj = await getCityByCityNameAndCountryName(propertyTypeObj?.key, urlParams.country, urlParams.city);

    if (locationObj?.id) {
      // CITY SEEMS TO BE A VALID ONE.
      // CHECK IF REQUESTED URL MATCHES THE USER LANGUAGE FORMAT
      const cityPageUrlForCurrentLang = useUrl.getCityPageUrl(
        {
          propertyTypeSlug: propertyTypeObj.slug,
          urlFriendlyContinent: locationObj.urlFriendlyContinent,
          urlFriendlyCountry: locationObj.urlFriendlyCountry,
          urlFriendlyCity: locationObj.urlFriendlyName,
        },
        urlQuery,
      );

      const cityPagePathForCurrentLang = new URL(cityPageUrlForCurrentLang).pathname.toLowerCase();

      if (cityPagePathForCurrentLang !== urlPath) {
        // REQUESTED URL DOES NOT MATCH USER LANGUAGE FORMAT
        // REDIRECT USER TO THE APPROPRIATE URL FORMAT
        navigateTo(cityPagePathForCurrentLang, {
          redirectCode: 301,
          external: false,
        });
      }
    } else {
      // CITY IS NOT FOUND, THROW 404 TO CHECK THE REDIRECTION API
      const errorDescription = `### No such City '${urlParams.city}' in Country '${urlParams.country}'`;
      console.error(errorDescription);

      throw createError({
        statusCode: 404,
        statusMessage: errorDescription,
        data: useRouteManager.enrichedErrorResponse(errorDescription, urlPath),
      });
    }
  }
});
